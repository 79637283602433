<template>
  <footer class="bg-surface text-onSurface py-4 px-6 mt-4">
    <div class="text-center text-sm">
      <p>
        &copy; {{ new Date().getFullYear() }} KrabAlarm.
        <a href="/privacy">{{ $t("privacyLink") }}</a>
        . All rights reserved.
      </p>
      <!-- <p>Privacy | Terms of Service</p> -->
      <p>
        Powered by
        <a href="http://WeatherAPI.com">WeatherAPI.com</a>
      </p>
      <p class="mt-2">
        <a href="/partnersites" class="text-blue-400 hover:underline">
          {{ $t("footer.partners") }}
        </a>
        :
        <a
          href="https://hexa-byte.nl"
          target="_blank"
          class="text-blue-400 hover:underline"
        >
          Hexa-Byte
        </a>
        |
        <a
          href="https://krabalarm.nl"
          target="_blank"
          class="text-blue-400 hover:underline"
        >
          KrabAlarm
        </a>
        |
        <a
          href="https://intimateforum.nl"
          target="_blank"
          class="text-blue-400 hover:underline"
        >
          IntimateForum
        </a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "LayoutFooter",
};
</script>
