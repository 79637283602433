<template>
  <div
    v-if="!hasDismissed"
    class="fixed bottom-0 left-0 right-0 p-4 flex justify-between items-center shadow-lg z-50"
    :class="{
      'bg-primary text-onPrimary': !hasDismissed,
    }"
  >
    <p class="flex-1">{{ $t(message) }}</p>
    <button @click="dismiss" class="bg-error p-2 text-onError rounded">
      X
    </button>
  </div>
</template>

<script>
export default {
  name: "FooterPopup",
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hasDismissed: false,
    };
  },
  created() {
    this.checkDismissStatus();
  },
  methods: {
    checkDismissStatus() {
      // Haal de status van de melding op uit localStorage
      const dismissed = localStorage.getItem("notificationDismissed");
      if (dismissed === "true") {
        this.hasDismissed = true;
      }
    },
    dismiss() {
      this.hasDismissed = true;
      // Sla op dat de melding is weggeklikt in localStorage
      localStorage.setItem("notificationDismissed", "true");
    },
  },
};
</script>
