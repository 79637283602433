<template>
  <DefaultLayout>
    <div>
      <!-- Install Button for PWA -->
      <!-- <button
        v-if="installPromptEvent"
        @click="installPWA"
        class="install-button bg-primary text-onPrimary px-4 py-2 rounded fixed bottom-4 right-4 shadow-md"
      >
        Installeer App
      </button> -->

      <!-- Temp offline -->
      <!-- <CookieConsent /> -->

      <!-- Router-view for rendering different pages -->
      <router-view></router-view>

      <!-- Melding onderaan de pagina -->
      <FooterPopup v-if="showNotification" :message="notificationMessage" />
    </div>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
// import CookieConsent from "@/components/ui/CookieConsent.vue";
import FooterPopup from "@/components/ui/FooterPopup.vue";
import { onMounted } from "vue";

export default {
  components: {
    DefaultLayout,
    FooterPopup,
    // CookieConsent,
  },
  setup() {
    // Request permission for notifications
    onMounted(() => {
      if ("Notification" in window && Notification.permission === "default") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            console.log("Notificaties toegestaan.");
          } else {
            console.log("Notificaties geweigerd.");
          }
        });
      }
    });
  },
  data() {
    return {
      installPromptEvent: null, // Store the installation event
      showNotification: true,
      notificationMessage: "footerMessage",
    };
  },
  mounted() {
    // Listen for the `beforeinstallprompt` event
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault(); // Prevent the default prompt
      this.installPromptEvent = event; // Save the event for later use
    });

    // Load Google scripts after Vue app initialization
    this.loadGoogleScripts();
  },
  methods: {
    installPWA() {
      if (this.installPromptEvent) {
        this.installPromptEvent.prompt(); // Show the installation prompt
        this.installPromptEvent.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("App successfully installed!");
          } else {
            console.log("User rejected the installation.");
          }
          this.installPromptEvent = null; // Reset the event after use
        });
      }
    },
    loadGoogleScripts() {
      // Load Google Tag Manager script
      const gtmScript = document.createElement("script");
      gtmScript.src =
        "https://www.googletagmanager.com/gtag/js?id=G-PWL6V8F43D409";
      gtmScript.async = true;
      gtmScript.onload = () => {
        // Initialize Google Tag Manager once the script is loaded
        if (!window.dataLayer) {
          window.dataLayer = []; // Define dataLayer if not already defined
        }

        // Function to push events to dataLayer
        function gtag() {
          window.dataLayer.push(arguments);
        }

        // Initialize Google Analytics with the tracking ID
        gtag("js", new Date());
        gtag("config", "G-PWL6V8F43D409");
      };
      document.head.appendChild(gtmScript);

      // Load Google Ads script (asynchronously)
      // const adsScript = document.createElement("script");
      // adsScript.src =
      //   "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7962806832683296";
      // adsScript.async = true;
      // adsScript.crossOrigin = "anonymous";
      // document.head.appendChild(adsScript);
    },
  },
};
</script>
