import { createRouter, createWebHistory } from "vue-router";
import { useI18n } from "vue-i18n";
import i18n from "@/i18n";
import IntegrationPage from "@/pages/IntegrationPage.vue";

const Home = () => import("@/pages/HomePage.vue");
const Privacy = () => import("@/pages/PrivacyPage.vue");
const Winterforecast2024 = () => import("@/pages/WinterForecast2024Page.vue");
const Partnersites = () => import("@/pages/PartnersitesPage.vue");

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      titleKey: "home_title",
      description: "home_description",
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
    meta: {
      titleKey: "privacy_title",
      description: "privacy_description",
    },
  },
  {
    path: "/winterforecast2024",
    name: "winterforecast2024",
    component: Winterforecast2024,
    meta: {
      titleKey: "winterforecast_title",
      description: "krabalarm_description",
    },
  },
  {
    path: "/partnersites",
    name: "Partnersites",
    component: Partnersites,
    meta: {
      titleKey: "partnersites_title",
      description: "partnersites_description",
    },
  },
  {
    path: "/integration",
    name: "integration",
    component: IntegrationPage,
    meta: {
      titleKey: "integration_title",
      description: "integration_description",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
});

// Voeg een globale router hook toe om de titel aan te passen
router.beforeEach((to, from, next) => {
  const { t } = i18n.global; // Verkrijg de globale vertaalfunctie
  if (to.meta.titleKey) {
    document.title = t(to.meta.titleKey); // Stel de titel in op basis van de vertaling
  } else {
    document.title = t("title"); // Gebruik de standaard titel als fallback
  }
  next();
});

router.afterEach((to) => {
  // Google Analytics code
  gtag("event", "page_view", {
    page_path: to.path,
    page_title: document.title,
  });

  const { t } = useI18n(); // Haal vertaalfunctie op

  if (to.meta.titleKey) {
    document.title = t(to.meta.titleKey); // Stel de paginatitel in
  }

  const descriptionMeta = document.querySelector('meta[name="description"]');
  if (descriptionMeta) {
    descriptionMeta.setAttribute("content", t(to.meta.description)); // Stel de meta description in
  } else {
    const newMeta = document.createElement("meta");
    newMeta.name = "description";
    newMeta.content = t(to.meta.description);
    document.head.appendChild(newMeta);
  }
});

export default router;
