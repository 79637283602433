<template>
  <header
    class="bg-primary text-onPrimary py-4 px-6 flex items-center justify-between"
  >
    <div class="flex items-center">
      <a href="/">
        <!-- Dynamisch logo op basis van schermgrootte -->
        <img :src="logoSrc" :alt="logoAlt" :class="logoClasses" />
      </a>
    </div>

    <!-- Grote schermen: normale navigatie -->
    <div class="hidden md:flex items-center space-x-4">
      <a href="/winterforecast2024" class="hover:underline"
        >Winter Forecast 2024</a
      >
      <a href="/integration" class="hover:underline">Integration</a>

      <!-- Taalwisselaar -->
      <select
        v-model="locale"
        class="bg-transparent border-none text-onPrimary cursor-pointer"
      >
        <option class="text-onSurface" value="en">EN</option>
        <option class="text-onSurface" value="nl">NL</option>
      </select>
    </div>

    <!-- Kleine schermen: hamburger menu -->
    <button
      @click="toggleMenu"
      class="md:hidden h-16 text-onPrimary focus:outline-none"
    >
      ☰
    </button>

    <!-- Zijbalk menu -->
    <div
      v-if="isMenuOpen"
      class="fixed inset-0 bg-black bg-opacity-50 z-50"
      @click="toggleMenu"
    ></div>
    <nav
      :class="[
        'fixed top-0 right-0 h-full bg-primary w-64 shadow-lg transform transition-transform duration-300',
        isMenuOpen ? 'translate-x-0' : 'translate-x-full',
      ]"
      class="p-6 flex flex-col space-y-4 z-50"
    >
      <button @click="toggleMenu" class="self-end text-onPrimary">✕</button>
      <a href="/" class="hover:underline">{{ $t("title") }}</a>
      <br />
      <a href="/winterforecast2024" class="hover:underline">{{
        $t("winterforecast_short")
      }}</a>
      <a href="/integration" class="hover:underline">{{
        $t("integration_title")
      }}</a>
      <select
        v-model="locale"
        class="bg-transparent border-none text-onPrimary cursor-pointer"
      >
        <option class="text-onSurface" value="en">EN</option>
        <option class="text-onSurface" value="nl">NL</option>
      </select>
    </nav>
  </header>
</template>

<script>
export default {
  name: "LayoutHeader",
  data() {
    return {
      locale: this.$i18n.locale, // Initialiseer de locale op basis van de huidige taal
      windowWidth: window.innerWidth, // Houd de schermgrootte bij
      isMenuOpen: false, // Houd bij of het menu open is
    };
  },
  computed: {
    logoSrc() {
      return this.windowWidth <= 768
        ? "img/icons/favicon-96x96.png"
        : "img/icons/web-app-manifest-512x512.png";
    },
    logoAlt() {
      return this.windowWidth <= 768 ? "Logo (Mobile)" : "Logo (Desktop)";
    },
    logoClasses() {
      return this.windowWidth <= 768 ? "h-16 w-auto" : "h-60 w-auto";
    },
  },
  watch: {
    locale(newLocale) {
      this.$i18n.locale = newLocale;
      localStorage.setItem("appLanguage", newLocale);
    },
  },
  mounted() {
    const savedLanguage = localStorage.getItem("appLanguage");
    if (savedLanguage) {
      this.$i18n.locale = savedLanguage;
      this.locale = savedLanguage;
    }
    window.addEventListener("resize", this.updateWindowWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>
