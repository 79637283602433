<template>
  <div class="min-h-screen bg-background text-onBackground">
    <div class="max-w-3xl mx-auto px-4 py-8">
      <h1 class="text-3xl font-semibold text-primary">
        {{ $t("integration_title") }}
      </h1>

      <section class="mt-6">
        <p class="text-lg">{{ $t("integration_intro") }}</p>
      </section>

      <section class="mt-8">
        <h2 class="text-2xl font-semibold">{{ $t("webhook_api") }}</h2>
        <p class="mt-2">{{ $t("webhook_api_description") }}</p>
      </section>

      <section class="mt-8">
        <h2 class="text-2xl font-semibold">{{ $t("supported_platforms") }}</h2>
        <p class="mt-2">{{ $t("supported_platforms_description") }}</p>
      </section>

      <section class="mt-8">
        <h2 class="text-2xl font-semibold">{{ $t("upcoming_steps") }}</h2>
        <ul class="mt-2 list-disc pl-5">
          <li>{{ $t("step_1") }}</li>
          <li>{{ $t("step_2") }}</li>
          <li>{{ $t("step_3") }}</li>
        </ul>
      </section>

      <section class="mt-8 border-t pt-4">
        <h3 class="text-xl font-semibold">{{ $t("get_involved") }}</h3>
        <div class="mt-2" v-html="$t('get_involved_description')"></div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
